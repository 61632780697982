export const orderStatus = {
  '-1': '全部',
  '1': '待支付', //待付款 
  '2': '已关闭', //已取消 
  '3': '待发货', //代发货 
  '4': '已发货', //待收货 
  '5': '待评价', //待评价 
  '6': '已完成', //已完成 
  '7': '已退款', //售后退款
  '8': '退款中', //申请退款
}

export const orderStatusList = Object.keys(orderStatus).map(item => {
  return {
    process: item,
    label: orderStatus[item]
  }
})