<!--  -->
<template>
  <div>
    <el-dialog :title="dialog.title" :visible.sync="dialog.dialogVisible" @close="handleClose" width="40%">
      <el-form ref="setForm" :model="formData" :rules="formRules" label-width="auto">
        <el-form-item label="退款金额" prop="money" required>
          <el-input v-model="formData.money" placeholder="请输入退款金额" clearable></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="refundMsg" required>
          <el-input v-model="formData.refundMsg" placeholder="请输入备注" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    dialog: {
      type: Object,
      default: function(value) {
        return {
          title: '提示',
          dialogVisible: false
        }
      }
    },
    orderInfo: Object
  },
  data() {
    return {
      formData: {
        money: '',
        refundMsg: ''
      },
      formRules: {
        money: [{ required: true, message: '请输入退款金额', trigger: 'blur' }],
        refundMsg: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      }
    }
  },
  watch: {
    orderInfo(v) {
      console.log(v)
      this.formData.money = v.totalPrice
      this.formData.orderId = v.orderId
    }
  },
  methods: {
    handleConfirm() {
      this.$refs['setForm'].validate(valid => {
        if (!valid) return
        console.log(this.formData)
        this.$emit('confirm', this.formData)
      })
    },
    handleClose() {
      this.formData = {
        money: '',
        refundMsg: ''
      }
    }
  },
  mounted() {},
  components: {}
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
</style>
