<template>
  <div>
    <TitleBarBaseVue />
    <el-tabs v-model="params.process" type="card" @tab-click="handleTabProcessClick">
      <!-- <el-tab-pane v-for="item in tabProcessList" :key="item.process" :label="`${item.label}(${item.num ? item.num : 0})`" :name="item.process"></el-tab-pane> -->
      <el-tab-pane :label="`全部(${tabProcess[-1] || 0})`" name="-1"></el-tab-pane>
      <el-tab-pane :label="`待发货(${tabProcess[3] || 0})`" name="3"></el-tab-pane>
      <el-tab-pane :label="`已发货(${tabProcess[4] || 0})`" name="4"></el-tab-pane>
      <el-tab-pane :label="`申请退款(${tabProcess[8] || 0})`" name="8"></el-tab-pane>
      <el-tab-pane :label="`已经退款(${tabProcess[7] || 0})`" name="7"></el-tab-pane>
      <el-tab-pane :label="`待支付(${tabProcess[1] || 0})`" name="1"></el-tab-pane>
      <el-tab-pane :label="`已关闭(${tabProcess[2] || 0})`" name="2"></el-tab-pane>
    </el-tabs>
    <el-form :inline="true" :model="formInline">
      <el-form-item>
        <el-date-picker
          v-model="date"
          type="daterange"
          align="right"
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="changeDate"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input v-model="params.keyWords" placeholder="请输入查询条件" clearable @change="changename"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="havesearch()">搜索</el-button>
        <el-button v-show="params.process == 3" type="danger" @click="exportCurntPage()">导出当前页</el-button>
        <el-button v-show="params.process == 3" type="danger" @click="exportAllPage()">导出全部</el-button>
        <el-upload v-show="params.process == 3" class="upload_wrapper" action="" :http-request="uploadFile" :show-file-list="false">
          <el-button type="warning">批量导入</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <el-table :data.sync="tableList" border>
      <el-table-column label="买家" min-width="120">
        <template slot-scope="scope">
          <!-- <div>用户ID：{{ scope.row.ordererName }}</div> -->
          <div>用户姓名：{{ scope.row.name }}</div>
          <div>订单编号：{{ scope.row.code }}</div>
        </template>
      </el-table-column>
      <el-table-column label="收货人" min-width="100">
        <template slot-scope="scope">
          <div>{{ scope.row.receName }} {{ scope.row.recePhone }}</div>
          <div>{{ scope.row.receAddress }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="goodsImg" label="商品信息" min-width="200" align="center">
        <template slot-scope="scope">
          <el-row :gutter="10" v-for="(item, index) in scope.row.list" :key="index" style="border:1px solid #eaeaea;padding: 5px">
            <el-col :span="3">
              <img :src="item.skuImage" width="100%" />
            </el-col>
            <el-col :span="13" style="text-align: left">
              <div class="spu-name">
                <!-- <el-tag type="danger" v-if="scope.row.orderType != '0'">{{ scope.row.orderType == '1' ? '砍价' : scope.row.orderType == '2' ? '拼团' : '' }}</el-tag> -->
                {{ item.goodsName }}
              </div>
              <div class="spec-info">{{ item.skuOwnSpecText }}</div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content" style="color:red;">
                ￥{{ item.buyPrice }}
                <!-- + 优惠券￥0.96 + 积分￥0.96 -->
              </div>
              <div class="grid-content">×{{ item.buyVal }}件</div>
              <!-- <div class="grid-content" v-if="item.status != '0'">
                <el-tag type="danger" @click="handleOrderItemStatus(item)">改价</el-tag>
              </div> -->
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="totalPrice" label="总价" min-width="50" align="center"></el-table-column>
      <el-table-column prop="createTime" label="下单时间" width="200" align="center">
        <template slot-scope="scope">{{ scope.row.createTime | dateformat('YYYY-MM-DD HH:mm:ss') }}</template>
      </el-table-column>

      <el-table-column prop="process" label="订单状态" width="120" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.process">{{ scope.row.process | filtersOrderStatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="params.process == 2 || params.process == 7 || params.process == 8" prop="msg" label="备注" width="200" align="center"> </el-table-column>
      <el-table-column label="操作" min-width="100" align="center">
        <template slot-scope="scope">
          <HandleBtns :status="scope.row.process" @clickCallback="clickCallback($event, scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
    <PromptDialog :dialog="promptDialog" :orderInfo="selectOrderInfo" @confirm="orderRefundConfirmAgree" />
  </div>
</template>
<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
// import { ordersPage, reviewOrderOverview, reviewOrdersExport, revieWordersImport, mouorderrefundsAgree, mouorderrefundsRefuse } from '@/api/ShenheController'
import * as orderApi from '@/api/ShenheController'
import HandleBtns from './components/HandleBtns.vue'
import { orderStatus, orderStatusList } from '@/utils/const.js'
import { reportReport } from '@/utils/util'
import PromptDialog from './components/PromptDialog.vue'
export default {
  name: 'ordershenhe',
  components: {
    TitleBarBaseVue,
    HandleBtns,
    PromptDialog
  },
  inject: ['reload'],
  data() {
    return {
      value: '',
      formInline: {
        goodname: ''
      },
      params: {
        pageNo: 1,
        pageSize: 5,
        endTime: '',
        startTime: '',
        keyWords: '',
        process: '-1'
      },
      tabProcessList: orderStatusList,
      tableList: [
        {
          id: '1',
          name: '2',
          orderStatus: 1
        },
        {
          id: '2',
          name: '2',
          orderStatus: 2
        },
        {
          id: '3',
          name: '2',
          orderStatus: 3
        },
        {
          id: '4',
          name: '2',
          orderStatus: 4
        }
      ],
      total: 0,
      date: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      promptDialog: {
        title: '同意退款',
        dialogVisible: false
      },
      selectOrderInfo: null
    }
  },
  computed: {
    tabProcess() {
      let tab = {}
      this.tabProcessList.forEach(item => {
        tab[item.process] = item.num
      })
      return tab
    }
  },
  mounted() {
    this.fecthList()
    // this.getReviewOrderOverview()
  },
  methods: {
    handleTabProcessClick() {
      this.fecthList()
    },
    // 按钮回调
    clickCallback(methods, row) {
      this[methods](row)
    },
    // 订单详情
    handleOrderDetail(row) {
      console.log(row)
    },
    // 分佣详情
    handleFenYongDetail(row) {
      console.log(row)
    },
    // 物流发货
    handleLogisticsDelivery(row) {
      console.log(row)
    },
    //  查看物流
    handleLogisticsDetail(row) {
      console.log(row)
    },
    //  退款详情
    handleRefundDetail(row) {
      console.log(row)
    },
    //  退货物流
    handleRefundLogistics(row) {
      console.log(row)
    },
    // 发优惠券
    handleSendCoupon(row) {
      console.log(row)
    },
    // 同意退款
    handleOrderRefundResolve(row) {
      this.selectOrderInfo = row
      this.promptDialog.dialogVisible = true
    },
    async orderRefundConfirmAgree(params) {
      let [err, res] = await orderApi.mouorderrefundsAgree(params)
      if (err) {
        console.log(err)
        return this.$message.error(err.message || '退款失败')
      }
      console.log(res)
      this.$message.success(res.message || '退款成功')
      this.promptDialog.dialogVisible = false
      this.fecthList()
    },
    async handleOrderRefundReject(row) {
      const promptResult = await this.$prompt('请输入备注', '拒绝退款', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '不能为空哦'
      }).catch(err => err)
      if (promptResult.action == 'confirm') {
        let [err, res] = await orderApi.mouorderrefundsRefuse({ orderId: row.orderId, refundMsg: promptResult.value })
        if (err) {
          console.log(err)
          return this.$message.error(err.message || '拒绝失败')
        }
        console.log(res)
        this.$message.success(res.message || '拒绝退款成功')
        this.fecthList()
      }
    },
    // 拒绝
    handleOrderReject(row) {
      console.log(row)
    },
    changeDate(v) {
      if (v) {
        this.params.startTime = v[0]
        this.params.endTime = v[1]
      } else {
        this.params.startTime = ''
        this.params.endTime = ''
        this.handleSearch()
      }
    },
    async fecthList() {
      let [err, res] = await orderApi.ordersPage(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取订单列表失败')
      }
      // console.log(res)
      this.tableList = res.data.records
      this.total = res.data.total
      this.getReviewOrderOverview()
    },
    changename(v) {
      !v && this.havesearch()
    },
    havesearch() {
      console.log(this.params)
      this.fecthList()
    },
    async getReviewOrderOverview() {
      let [err, res] = await orderApi.reviewOrderOverview()
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取订单数量失败')
      }
      this.tabProcessList = res.data
    },
    proagree(row) {
      this.$confirm(`确定要审核同意吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let { ...ss } = {}
          ss.reviewId = row.reviewId
          ss.status = 1
          // this.integralorderUpdateReq(ss)
        })
        .catch(() => {})
    },
    prorefuse(row) {
      this.$confirm(`确定要拒绝吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let { ...ss } = {}
          ss.reviewId = row.reviewId
          ss.status = 2
          // this.integralorderUpdateReq(ss)
        })
        .catch(() => {})
    },

    async integralorderUpdateReq(data) {
      let [err, res] = await integralorderUpdate(data)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      } else {
        this.$message.success('操作成功')
        this.fecthList()
      }
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.fecthList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.fecthList()
    },
    async exportCurntPage() {
      let idList = this.tableList.map(item => item.orderId)
      let [err, res] = await orderApi.reviewOrdersExport({ idList })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '导出失败')
      }
      // console.log(res)
      reportReport(res)
    },
    async exportAllPage() {
      let [err, res] = await orderApi.reviewOrdersExport()
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '导出失败')
      }
      // console.log(res)
      reportReport(res)
    },
    async uploadFile(params) {
      console.log(params)
      const { file } = params
      let fromData = new FormData()
      fromData.append('file', file)
      const [err, res] = await orderApi.revieWordersImport(fromData)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '上传失败')
      }
      this.fecthList()
    }
  },
  filters: {
    filtersOrderStatus(v) {
      return orderStatus[v]
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-tag {
  color: black;
}
.pageCo {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.spu-name {
  font-size: 13px;
}

.spec-info {
  margin-top: 10px;
  font-size: 12px;
  color: #7b7b7b;
}

.upload_wrapper {
  display: inline-block;
  margin-left: 10px;
}
</style>
