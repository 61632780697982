import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 评价审核列表
 * @returns
 */
export const reviewPage = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/review/eval/page`,
      params: params,
      method: 'get'
    })
  )
}

/**
 * 订单审核列表
 * @returns
 */
export const ordersPage = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/review/order/page`,
      params: params,
      method: 'get',
      isLoading: true
    })
  )
}
/**
 * 获得各种订单的数量
 * @returns
 */
export const reviewOrderOverview = () => {
  return awaitWrap(
    request({
      url: `/review/order/overview`,
      method: 'get',
    })
  )
}

/**
 * 审核接口
 * @returns
 */
export const reviewShenhe = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/review/verify`,
      method: 'POST',
      data
    })
  )
}
/**
 * 同意退款
 * @param {} orderId
 * @param {} money
 * @param {} refundMsg
 * @returns
 */
export const mouorderrefundsAgree = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/mouorderrefunds/agree`,
      method: 'post',
      data
    })
  )
}
/**
 * 拒绝退款
 * @param {} orderId
 * @param {} refundMsg
 * @returns
 */
export const mouorderrefundsRefuse = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/mouorderrefunds/refuse`,
      method: 'post',
      data
    })
  )
}
/**
 * 导出订单
 * @param {Array} idList //
 * @returns
 */
export const reviewOrdersExport = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/review/orders/export`,
      method: 'POST',
      data,
      responseType: 'blob'
    })
  )
}

/**
 * 导入订单
 * @param {*} file 上传的文件
 * @param {contentType} 'form-data'
 * @returns
 */
export const revieWordersImport = formData => {
  return awaitWrap(
    request({
      url: '/review/orders/import',
      method: 'POST',
      data: formData,
      contentType: 'multipart/form-data'
    })
  )
}