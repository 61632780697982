<!--  -->
<template>
  <div class="btn_wrapper">
    <div v-for="(item, itemIndex) in btnList" :key="itemIndex" :class="{ oirder_btn: hasShow(item.whiteList) }">
      <el-link v-if="hasShow(item.whiteList)" :type="item.btnType ? item.btnType : 'primary'" :underline="false" @click="clickCallback(item.methods)">
        {{ item.name }}
      </el-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HandleBtns',
  props: {
    // btnList: {
    //   type: Array
    // },
    status: [String, Number]
  },
  data() {
    return {
      btnList: [
        // {
        //   name: '订单详情',
        //   methods: `handleOrderDetail`,
        //   whiteList: [1, 2, 3, 4, 6]
        // },
        // {
        //   name: '分佣详情',
        //   methods: `handleFenYongDetail`,
        //   whiteList: [3, 4, 8]
        // },
        // {
        //   name: '物流发货',
        //   methods: `handleLogisticsDelivery`,
        //   whiteList: [3]
        // },
        // {
        //   name: '查看物流',
        //   methods: `handleLogisticsDetail`,
        //   whiteList: [4]
        // },
        // {
        //   name: '退款详情',
        //   methods: `handleRefundDetail`,
        //   whiteList: [7, 8]
        // },
        // {
        //   name: '退货物流',
        //   methods: `handleRefundLogistics`,
        //   whiteList: [7, 8]
        // },
        // {
        //   name: '发优惠券',
        //   methods: `handleSendCoupon`,
        //   whiteList: [1],
        //   btnType: 'danger'
        // },
        {
          name: '同意退款',
          methods: `handleOrderRefundResolve`,
          whiteList: [8],
          btnType: 'danger'
        },
        {
          name: '拒绝退款',
          methods: `handleOrderRefundReject`,
          whiteList: [8],
          btnType: 'danger'
        }
        // {
        //   name: '拒绝', // 拒绝发货
        //   methods: `handleOrderReject`,
        //   whiteList: [3],
        //   btnType: 'danger'
        // }
      ]
    }
  },
  methods: {
    hasShow(whiteList) {
      return whiteList.includes(this.status)
    },
    clickCallback(methodsWords) {
      this.$emit('clickCallback', methodsWords)
    }
  }
}
</script>
<style lang="less" scoped>
.btn_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.oirder_btn {
  flex: 0 0 50%;
}
/deep/ .el-link {
  margin: 10px;
}
</style>
